/* INITIALIZERS + DEFAULTS
 * ============================== */

*,
*:before,
*:after {
  box-sizing: border-box;
}
/* html {
  font-size: 65%;
} */

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
}
